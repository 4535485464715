import ScrollMagic from "ScrollMagic";
// import "scrollmagic/scrollmagic/minified/plugins/debug//.addIndicators.min";
import Highcharts from 'highcharts'
import addMore from "highcharts/highcharts-more";
import addDrilldown from "highcharts/modules/drilldown";
import addNoData from "highcharts/modules/no-data-to-display";
import _PersonData from "./person_data";

const PersonData = _PersonData.sort( () => .5 - Math.random() );
const RandomNumArray = [0,1,2,3,4,5,6,7,8,9].sort( () => .5 - Math.random() );

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function checkCookie() {
  let user = getCookie("username");
  if (user != "") {
    alert("Welcome again " + user);
  } else {
    user = prompt("Please enter your name:", "");
    if (user != "" && user != null) {
      setCookie("username", user, 365);
    }
  }
}

addMore(Highcharts)
addDrilldown(Highcharts)
addNoData(Highcharts)

var $ = require('jquery');
window.jQuery = $;
window.$ = $;

const initChart = () => {
  window.chartColor = ['#7999d8', '#eaba72', '#e27377', '#24C8CB'];
  window.chart = Highcharts.chart('hc-container', {
    title: {
      text: 'Verteilung von Kanälen'
    },
    yAxis: {
      title: {
        text: 'Verkäufe',
      }
    },
    xAxis: {
      categories: []
    },
    labels: {
      items: [{
        html: 'Verteilung von Kanälen',
        style: {
          left: '50px',
          top: '0px',
          color: ( // theme
            Highcharts.defaultOptions.title.style &&
            Highcharts.defaultOptions.title.style.color
          ) || 'black'
        }
      }]
    },
    credits: {
       enabled: false
    },
    plotOptions: {
      series: {
      states: {
        hover: {
        enabled: false
        },
        inactive: {
        opacity: 1
        }
      }
      }
    },

    series: [{
      type: 'column',
      name: 'Shop',
      data: [],
      color: window.chartColor[0]
    }, {
      type: 'column',
      name: 'Amazon',
      data: [],
      color: window.chartColor[1]
    }, {
      type: 'column',
      name: 'Ebay',
      data: [],
      color: window.chartColor[2]
    }, {
      type: 'spline',
      name: 'Durchschnitt',
      data: [],
      marker: {
        lineWidth: 2,
        lineColor: window.chartColor[3],
        fillColor: 'white'
      }
    }, {
      type: 'pie',
      name: 'Live Kanal Übersicht',
      data: [{
        name: 'Shop',
        y: 1,
        color:window. chartColor[0]
      }, {
        name: 'Amazon',
        y: 1,
        color: window.chartColor[1]
      }, {
        name: 'Ebay',
        y: 1,
        color: window.chartColor[2]
      }],
      center: [70, 55],
      size: 100,
      showInLegend: false,
      dataLabels: {
        enabled: false
      }
    }]
  });  
}

const addChartElement = () => {
  let currentTime =  Intl.DateTimeFormat('de-DE', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
    }).format(new Date())
  let value
  let values = []
 let categories = window.chart.xAxis[0].categories;
 categories.push(currentTime)
 window.chart.xAxis[0].setCategories(categories);

  [0,1,2].map(x => {
    value = Math.floor(Math.random() * 10)+1
    if (window.chart.series[x].points.length > 5){
    window.chart.series[x].removePoint(0)
    }
    window.chart.series[x].addPoint(value)
    values.push(value)
  })


    if (window.chart.series[3].points.length > 5){
    window.chart.series[3].removePoint(0)
    }
    window.chart.series[3].addPoint(Math.round(values.reduce((prev, next) => prev + next)/values.length))

  window.chart.series[4].setData([{
      name: 'Shop',
      y: chart.series[0].points.map(x => x.y).reduce((prev, next) => prev + next),
      color: window.chartColor[0]
    }, {
      name: 'Amazon',
      y: chart.series[1].points.map(x => x.y).reduce((prev, next) => prev + next),
      color: window.chartColor[1]
    }, {
      name: 'Ebay',
      y: chart.series[2].points.map(x => x.y).reduce((prev, next) => prev + next),
      color: window.chartColor[2]
    }
  ], false)
}

const renderCustomer = () => {
    window.CustomerMockupIndex = (window.CustomerMockupIndex||0) +1
  let p = PersonData[(window.CustomerMockupIndex-1 % 100)]
  let avatar = RandomNumArray[((window.CustomerMockupIndex-1) % 10)]

  let rows = $(`<tr><td class="m-0"><div class="flex items-center space-x-3"><div class="avatar"><div class="w-12 h-12 mask mask-squircle"><img src="/avatars/${p.gender}/${avatar}.jpg" alt="${p.firstname} ${p.lastname}"></div></div><div><div class="font-bold">${p.firstname} ${p.lastname}</div><div class="text-sm opacity-50">${p.city} (DE)</div></div></div></td><td class="m-0 hidden 2xl:block">${p.company} ${p.companySuffix}<br><span class="badge badge-outline badge-sm">${p.jobTitle}</span></td></tr>`);
  rows.hide();
  $('#tbodyCostumerMackup tr:first-child').before(rows);
  rows.fadeIn();
}

const startCustomerClock = () => {
  setInterval(() => {
    addChartElement()
  }, 2000);
}

const progressFormData = (f) => {
  $("#form-error").addClass("hidden")
  $("#form-success").addClass("hidden")
  const name = f.find("#form-name").val()
  const email = f.find("#form-email").val()
  const phone = f.find("#form-phone").val()
  const message = f.find("#form-message").val()

  if (name.length === 0 || email.length === 0 || message.length === 0 ){
    $("#form-error").removeClass("hidden")
    $("#form-error").find("label").html("Bitte prüfen Sie, ob alle Felder richtig ausgefüllt wurden.")
  }else{
    const data = {
      name: name,
      email: email,
      phone: phone,
      message: message,
      referrer: document.referrer,
      agent: navigator.userAgent,
      cta: window.cta
    }
    $("#form-submit-button").addClass("loading")
    fetch('https://crm.vincent-thelang.de/api/form/XGWm27PkHj53UDLJwPHCU86QTM1KX4IKOBBqRLTYAZDVM0VAWS9fl', {
      method: 'POST',
      headers: {
      'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
      $("#form-submit-button").removeClass("loading")
      if (data.success){
      f.find("#form-name").val("")
      f.find("#form-email").val("")
      f.find("#form-phone").val("")
      f.find("#form-message").val("")


      $("#form-success").removeClass("hidden")
      }else{
      $("#form-error").removeClass("hidden")
      $("#form-error").find("label").html("Bei dem Absenden der Nachricht ist ein Fehler aufgetreten. Bitte kontaktieren Sie <a class='link' href='mailto:sales@shopschnittstellen.de'>sales@shopschnittstellen.de</a>")
      }
    })
    .catch((error) => {
      $("#form-error").removeClass("hidden")
      $("#form-error").find("label").html("Bei dem Absenden der Nachricht ist ein Fehler aufgetreten. Bitte kontaktieren Sie <a class='link' href='mailto:sales@shopschnittstellen.de'>sales@shopschnittstellen.de</a>")
    });
  }
}

window.addEventListener('load', function () {
  $("#currentYear").text(" " + new Date().getFullYear() + " ")
  $("#hc-container").each(() => {
    initChart()
    addChartElement()
  })
  renderCustomer()

  var gmToken = location.search.split('gm=')[1];
  if (gmToken) {
    var href = location.href.replace('?gm=' + gmToken + '&agent=' + navigator.userAgent, '');
    var data = new FormData();
    data.append('token', gmToken);
    data.append('href', href);
    var xhr = new XMLHttpRequest();
    xhr.open('POST', 'https://crm.vincent-thelang.de/mailing/gm', true);
    xhr.send(data);
  };

  if ($("body").attr("id") != "landing-page"){
    $(".navbar").addClass("shadow-lg")
  }

  $("a").click(function( event ) {
    window.cta = $(this).data("cta")
    if ($("body").attr("id") != "landing-page" && $(this).attr("href")[0] === "#"){
      window.open("/"+$(this).attr("href"), "_self");
      event.preventDefault();

    }
  });

  $("#contact-form").submit(function( event ) {
    progressFormData($(this))
    event.preventDefault();
  });
  setTimeout( () => {
    $("#portfolio-product-video").addClass('ready')
    $("#portfolio-picker-video").addClass('ready')
  }, 1000);
  const controller = new ScrollMagic.Controller();

  const portfolioCallbacks = {
    dashboardEnter: () => {
      addChartElement()
      window.ChartInterval = window.setInterval(() => {
        addChartElement()
      }, 2000);
    },
    dashboardLeave: () => {
      if(window.ChartInterval) window.clearInterval(window.ChartInterval)
      window.ChartInterval = null
    },
    customerEnter: () => {
      renderCustomer()
      window.CustomerInterval = window.setInterval(() => {
        renderCustomer()
      }, 2000);
    },
    customerLeave: () => {
      if(window.CustomerInterval) window.clearInterval(window.CustomerInterval)
      window.CustomerInterval = null
    },
    productEnter: () => {
      if (!$("#portfolio-product-video").hasClass('ready')) return false
      $("#portfolio-product-video").trigger('play')
    },
    productLeave: () => {
      if (!$("#portfolio-product-video").hasClass('ready')) return false
      $("#portfolio-product-video").trigger('pause')
    },
    pickerEnter: () => {
      if (!$("#portfolio-picker-video").hasClass('ready')) return false
      $("#portfolio-picker-video").trigger('play')
    },
    pickerLeave: () => {
      if (!$("#portfolio-picker-video").hasClass('ready')) return false
      $("#portfolio-picker-video").trigger('pause')
    }
  }
  $("#hero-text").each(() => {
    new ScrollMagic.Scene({triggerElement: "#hero-text"})
      .setClassToggle(".navbar", "shadow-lg")
      //.addIndicators({name: "Startscreen"})
      .addTo(controller);
  })

  let screens = []
  $('.page-section').each(function() {
    const self = this
    let screen = new ScrollMagic.Scene({triggerElement: self, duration: $(self).height()})
        .on("enter", function (e) {
          $("a.nav-item").removeClass("font-extrabold")
          $('a.nav-item[href$="#'+$(self).attr("id")+'"]').addClass("font-extrabold")
        })
      //.addIndicators({name: $(self).attr("id")})
      .addTo(controller);
    screens.push(screen)
  })

  $('.portfolio-section').each(function() {
    const self = this
    let screen = new ScrollMagic.Scene({triggerElement: self, duration: $(self).height()})
      .on("enter", function (e) {
        const callback = portfolioCallbacks[$(self).attr("id").split("-")[1]+"Enter"]
        if (callback) callback()
        else console.log($(self).attr("id").split("-")[1]+"Enter")
      })
      .on("leave", function (e) {
        const callback = portfolioCallbacks[$(self).attr("id").split("-")[1]+"Leave"]
        if (callback) callback()
        else console.log($(self).attr("id").split("-")[1]+"Leave")
      })
      //.addIndicators({name: $(self).attr("id")})
      .addTo(controller);
    screens.push(screen)
  })

  const reportWindowSize = () => {
    screens.forEach((e) => {
      e.duration($(e.triggerElement()).height())
    })
  }

  window.addEventListener('resize', reportWindowSize);

})